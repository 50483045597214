.home-box {
  margin-top: 60px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 1300px;
  margin-bottom: 100px;
}

.home-title {
  font-size: 30px;
  font-weight: bold;
}

.home-legal {
  max-width: 600px;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.home-link {
  max-width: 280px;
  text-decoration: none;
  color: #4a90e2;
}

.home-link a:hover {
  color: #306198;
}
