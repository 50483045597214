@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;700&display=swap");

* {
  font-family: "Quicksand", sans-serif;
  font-size: 14px;
}

.background-light {
  background-color: #f9f9f9;
}

.margin-height-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.margin-bottom-15 {
  margin-bottom: 15px;
}

.margin-bottom-4 {
  margin-bottom: 4px;
}

.margin-right-30 {
  margin-right: 30px;
}

.margin-left-auto {
  margin-left: auto;
}

.margin-left-8 {
  margin-left: 8px;
}

.padding-height-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.padding-width-30 {
  padding-left: 30px;
  padding-right: 30px;
}

.bottom-fixed {
  position: fixed;
  bottom: 0px;
}

@media (max-width: 767px) {
  * {
    font-size: 12px;
  }
}
