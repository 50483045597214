.header {
  width: auto;
  height: auto;
}

.header-image {
  height: 25px;
}

.header-box {
  width: auto;
  font-weight: bold;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.header-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 4px;
  padding-right: 4px;
}

.active {
  border-bottom: 2px solid #9b7475;
}

@media screen and (max-width: 560px) {
  .header-box {
    width: auto;
    font-weight: bold;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
  }

  .header-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .translate {
    margin-top: 5px;
  }
}
