.footer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.footer-contain {
  font-size: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.footer-image {
  height: 25px;
}

.footer-links {
  text-align: center;
}

.footer-contain a {
  margin-inline: 10px;
  text-decoration: none;
  color: #888888;
  font-weight: 400;
}

.footer-contain a:hover {
  font-weight: 500;
}

.footer-socials {
  width: auto;
  display: flex;
  flex-direction: row;
}

.footer-socials a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #4a90e2;
}

.footer-socials a:hover {
  color: #306198;
}

.pci-logo {
  height: 30px;
  margin-left: 20px;
}

.footer-reference {
  text-align: center;
  font-size: 12px;
  margin-top: 10px;
  margin-right: 70px;
}

@media only screen and (max-width: 767px) {
  .footer-links {
    display: none;
  }

  .footer_socials {
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .footer-logo {
    display: none;
  }

  .pci-logo {
    height: 20px;
    margin-left: 20px;
  }

  .footer-reference {
    font-size: 10px;
    margin-right: 0px;
  }
}
