.legal-box {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: 150px;
}

.legal-text {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 200px;
  flex-wrap: wrap;
}

.legal-image {
  height: 300px;
  opacity: 0.8;
}

.legal-download {
  width: auto;
  display: flex;
  justify-content: space-between;
}

.legal-span {
  margin-left: 10px;
  margin-right: 10px;
}

.legal-text a {
  text-decoration: none;
  color: #4a90e2;
}

.legal-text a:hover {
  color: #306198;
}

@media (max-width: 767px) {
  .legal-download {
    width: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .legal-span {
    display: none;
  }

  .legal-policy-image {
    display: none;
  }

  .legal-box {
    margin-top: 80px;
  }
}
